import React from "react";
import createPersistedState from "use-persisted-state";
import dayjs from "dayjs";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DayJSUtils from "@date-io/dayjs";

import "./App.css";

import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isLeapYear from "dayjs/plugin/isLeapYear"; // rely on isLeapYear plugin
import weekOfYear from "dayjs/plugin/weekOfYear";

const DEBUG = false;

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);

const Grid = {
  'NO_BOX' : 'NO_BOX',
  'BOX_FILLED' : 'BOX_FILLED',
  'BOX' : 'BOX'
}

const NoBox = () => <span className="box noBox"></span>;
const Box = () => <span className="box"></span>;
const BoxFilled = () => <span className="box filled"></span>;

const Year = ({weeks}) => {
  return <div className="boxes">
    {weeks && weeks.length > 0 && weeks.map((week, index) => {
      switch(week) {
        case 'BOX_FILLED':
          return <BoxFilled key={`${index} ${week}`}></BoxFilled>
        case 'NO_BOX':
          return <NoBox key={`${index} ${week}`}></NoBox>
        default:
          return <Box key={`${index} ${week}`}></Box>
      }
    })}
  </div>
}

function App() {
  const useBirthDateState = createPersistedState("birthDate");
  const [birthDate, handleDateChange] = useBirthDateState(null);
  const formatString = "D MMM YYYY";
  const birth = dayjs(birthDate);
  const lifeExpectancyInYears = 90;
  const death = birth.add(lifeExpectancyInYears, "year");
  const today = dayjs();

  let years = [];

  for (let year = birth.year(); year <= death.year(); year++) {
    let weeks = [];
    for (
      let week = 1;
      week <= dayjs(new Date(year, 1, 1)).isoWeeksInYear();
      week++
    ) {
      if (
        (year === birth.year() && week < birth.week()) ||
        (year === death.year() && week >= death.week())
      ) {
        weeks.push(Grid.NO_BOX);
      } else if (
        year < today.year() ||
        (year === today.year() && week < today.week())
      ) {
        weeks.push(Grid.BOX_FILLED)
      } else {
        weeks.push(Grid.BOX)
      }
    }
    years.push(weeks);
  }

  if(DEBUG) console.log(JSON.stringify(years, null, 2));


  return (
    <div className="App">
      <div className="container">
        {!birthDate && (
          <>
              <center>
              <p>
                "I like the site. It is pleasingly sinister" - Luke, teacher
              </p>
            </center>


            <p className="question">Enter your</p>
          </>
        )}

        <header className="datePickerContainer">
          <div className="title">Birthday: </div>
          <div className="picker">
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <DatePicker
                className="datePicker"
                disableFuture
                openTo="year"
                format={formatString}
                views={["year", "month", "date"]}
                value={birthDate}
                onChange={handleDateChange}
                inputVariant={birthDate ? "standard" : "outlined"}
              />
            </MuiPickersUtilsProvider>
          </div>
        </header>
        {birthDate && (
          <>
            <div className="calendar">
              {years.map((weeks, index) => (<Year key={index} weeks={weeks} ></Year>))}
            </div>

            <div>
              <div className="legend">
                <div className="key">
                  <div className="keyItem"><BoxFilled></BoxFilled> week gone</div>
                  <div className="keyItem"><Box></Box> week to go</div>
                  <div className="keyItem">(assuming 90 year life)</div>
                </div>

                <div className="datePickerContainer">
                  <div className="title">Death: </div>
                  <MuiPickersUtilsProvider utils={DayJSUtils}>
                    <DatePicker
                      className="datePicker"
                      disabled
                      format={formatString}
                      maxDate={death}
                      value={death}
                      onChange={() => {}}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
          </>
        )}
        <br />

        <center>
          <p>
            <span role="img" aria-label="Warning sign">
              ⚠️
            </span>{" "}
            Warning: May cause existential angst.
          </p>
        </center>
      </div>

      {birthDate && (
        <div className="ad">
          <div>
            <a href="https://store.waitbutwhy.com/products/life-calendar">
              Ad: Buy the poster
            </a>
          </div>
          <sub>
            Thanks to{" "}
            <a href="https://www.ted.com/talks/tim_urban_inside_the_mind_of_a_master_procrastinator">
              Tim Urban
            </a>
          </sub>
        </div>
      )}
      <footer>
        <p>
        <div>
          You can{" "}
          <button
            className="wipe-button"
            href="#"
            onClick={() => {
              handleDateChange(null);
            }}>
            wipe data
          </button>{" "}
          at any time.
        </div>

        <div className="made-by">
          Made by <a href="https://www.louiechristie.com">Louie Christie</a>
        </div>
        </p>
      </footer>
    </div>
  );
}

export default App;
